import React from "react";
import styled, { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    transform: translateY(550px)
  } to {
    transform: translateY(0)
  }
`;
const Container = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  width: 75%;
  height: 60px;
  border: 5px solid
    ${({ $highlight }) => ($highlight ? "#bff90b" : "transparent")};
  background: ${({ $highlight }) => ($highlight ? "#090d0d" : "transparent")};
  animation: ${slideIn} 0.5s linear ${({ $delay }) => $delay}s backwards;
  color: #bff90b;
`;
const CustomName = styled.p`
  margin: 0;
  padding-right: 2%;
  padding-left: 5%;
  width: 125px;
  font-size: 150%;
  font-weight: bold;
  color: #bff90b;
  background: #090d0d;
  border-radius: 50px;
  overflow: scroll;
`;
const CustomLetter = styled.p`
  font-size: 120%;
  text-align: center;
  margin: 1%;
  color: #bff90b;
  width: 75px;
  overflow: scroll;
  background: #090d0d;
  border-radius: 50px;
`;

function PlayerDefine({ idx, player, currPlayerId, action }) {
  const highlight = idx === currPlayerId && action === "define";
  return (
    <Container $highlight={highlight} $delay={0.1 * idx}>
      <CustomName>{player.name}</CustomName>
      <CustomLetter data-testid={`letters ${idx}`}>
        {player.letter || "---"}
      </CustomLetter>
    </Container>
  );
}

export default PlayerDefine;
