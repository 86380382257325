import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import PlayerList from "../components/player-list";

import PlayerContext, { initialPlayers } from "../context/player-context";
import goback from "../img/flèche_retour_jaune.svg";

const Header = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  height: 5%;
  padding: 2%;
  animation: opacityAnim 1s linear 0.7s backwards;
`;
const Body = styled.div`
  position: absolute;
  top: 50px;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 85%;
  align-items: center;
  justify-content: space-around;
  padding-top: 5%;
`;
const CustomH1 = styled.h1`
  margin: 0;
  padding: 1%;
  font-size: 275%;
  color: white;
  animation: opacityAnim 1s linear;
`;
const CustomBtn = styled.button`
  width: 60%;
  height: 10%;
  font-size: 225%;
  background: #bff90b;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: opacityAnim 1s linear 0.7s backwards;
  color: #090d0d;
  font-family: "ClashGrotesk-Semibold";
`;

function Player() {
  const navigate = useNavigate();

  const { setPlayers } = useContext(PlayerContext);

  const [show, setShow] = useState(false);

  useEffect(() => {
    setPlayers(initialPlayers.players);
    setTimeout(() => {
      setShow(true);
    }, 2000);
  }, []);

  return (
    <div className="pagewrapper">
      {show && (
        <div className="triangle">
          <div className="triangle_outer clip"></div>
          <div className="triangle_inner clip"></div>
        </div>
      )}
      <Header>
        <img onClick={() => navigate("/")} src={goback} alt="goback" />
      </Header>
      <Body>
        <CustomH1>Who Play ?</CustomH1>
        <PlayerList />
        <CustomBtn onClick={() => navigate("/setting")}>Next</CustomBtn>
      </Body>
    </div>
  );
}

export default Player;
