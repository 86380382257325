import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";

import TimeContext from "../context/time-context";
import GameContext from "../context/game-context";
import PlayerContext from "../context/player-context";
import check from "../img/check.svg";
import cross from "../img/croixblanche.svg";
import jokerSVG from "../img/joker.svg";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border-radius: 20px;
  width: 90%;
  min-height: 225px;
  border: 5px solid transparent;
  background: #bff90b;
  animation: opacityAnim 1s ease-out ${({ $delay }) => $delay}s backwards;
`;
const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
`;
const Container = styled.div`
  display: flex;
  width: 75%;
  justify-content: space-between;
`;
const CustomName = styled.p`
  margin: 0;
  width: 90%;
  text-align: center;
  font-size: 175%;
  font-weight: bold;
  color: #090d0d;
  overflow: scroll;
`;
const CustomLabel = styled.p`
  font-size: 120%;
  min-width: 75px;
  margin: 0;
  color: #090d0d;
`;
const AnimatedCustomLabel = styled.p`
  font-size: 120%;
  min-width: 75px;
  margin: 0;
  color: #090d0d;
  animation: opacityAnim 0.5s linear;
`;
const CustomValue = styled.p`
  font-size: 160%;
  margin: 0;
  color: white;
  overflow: scroll;
  background: #090d0d;
  padding: 0 4%;
  border-radius: 50px;
`;
const CheckIMG = styled.img`
  width: 50px;
  background: #090d0d;
  border-radius: 50px;
  padding: 2% 7% 4% 7%;
  animation: ${({ $anim }) => ($anim ? "pulsate 0.2s linear" : "")};
`;
const CrossIMG = styled.img`
  width: 40px;
  background: #090d0d;
  border-radius: 50px;
  padding: 5% 9%;
  animation: ${({ $anim }) => ($anim ? "pulsate 0.2s linear" : "")};
`;
const JokerIMG = styled.img`
  width: 30px;
  background: #090d0d;
  border-radius: 50px;
  padding: 0 4% 3% 4%;
`;
const CustomBtn = styled.button`
  width: 50%;
  background: #090d0d;
  color: #bff90b;
  font-size: 150%;
  font-weight: bold;
  border: 2px solid black;
  border-radius: 25px;
  font-family: "ClashGrotesk-Semibold";
  animation: ${({ $anim }) => ($anim ? "pulsate 0.2s linear" : "")};
`;

function PlayerItem({
  idx,
  player,
  currPlayerId,
  playersToCopy,
  eliminatedPlayers,
  setPlayersToCopy,
  setEliminatedPlayers,
}) {
  const { timer, setTimer } = useContext(TimeContext);
  const { game, setGame } = useContext(GameContext);
  const { players, setPlayers } = useContext(PlayerContext);

  const [phase, setPhase] = useState(0); // 0 = default, 1 = askJoker, 2 = askResult
  const [checkAnim, setCheckAnim] = useState(false);
  const [crossAnim, setCrossAnim] = useState(false);
  const [noAnim, setNoAnim] = useState(false);
  const [yesAnim, setYesAnim] = useState(false);

  function addLetter(name) {
    return players.map((p) =>
      p.name === name
        ? { ...p, letter: p.letter + game.targetWord[p.letter.length] }
        : p
    );
  }
  function incrementStat(someplayers, name, stat) {
    return someplayers.map((p) =>
      p.name === name
        ? { ...p, stats: { ...p.stats, [stat]: p.stats[stat] + 1 } }
        : p
    );
  }
  function handleTryDown() {
    if (!player.redo && player.nbOfTry === 1) {
      handleFailedTry();
    } else {
      const nextPlayersToCopy = playersToCopy.map((p, i) =>
        i === idx ? { ...p, nbOfTry: p.nbOfTry - 1 } : p
      );
      setPlayersToCopy(nextPlayersToCopy);
      const nextPlayers = incrementStat(players, player.name, "nbFailedTry");
      setPlayers(nextPlayers);
    }
  }
  function handleFailedTry() {
    const letteredPlayers = addLetter(player.name);
    const definerId =
      currPlayerId >= players.length ? players.length - 1 : currPlayerId;
    const statPlayers = incrementStat(
      letteredPlayers,
      players[definerId].name,
      "nbLetterGiven"
    );
    const playerNextLetter = statPlayers.find(
      (p) => p.name === player.name
    ).letter;

    if (playerNextLetter === game.targetWord) {
      handleEndGame(statPlayers);
    } else {
      setPlayers(statPlayers);
      setPlayersToCopy(playersToCopy.filter((p) => p.name !== player.name));
    }
  }
  function handleEndGame(statPlayers) {
    const nextPlayers = statPlayers.filter((p) => p.name !== player.name);
    if (nextPlayers.length === 1) {
      endGame(statPlayers);
    } else {
      const elimPlayer = statPlayers.find((p) => p.name === player.name);
      const eliminated = [...eliminatedPlayers, elimPlayer];
      setPlayers(nextPlayers);
      setPlayersToCopy(playersToCopy.filter((p) => p.name !== player.name));
      setEliminatedPlayers(eliminated);
    }
  }
  function endGame(statPlayers) {
    setPlayers([...statPlayers, ...eliminatedPlayers]);
    setGame({ ...game, isRunning: false });
    setTimer({ ...timer, isPaused: true, isActive: false });
  }
  function handleTrickDone() {
    const nextPlayers = incrementStat(players, player.name, "nbCopied");
    setPlayers(nextPlayers);
    setPlayersToCopy(playersToCopy.filter((p) => p.name !== player.name));
  }
  function handleUseJoker() {
    setPlayers(
      players.map((p) => (p.name === player.name ? { ...p, redo: false } : p))
    );
    setPhase(2);
  }

  useEffect(() => {
    if (player.redo && player.nbOfTry === 0) {
      setPhase(1);
    } else {
      setPhase(0);
    }
  }, [player.nbOfTry]);

  return (
    <Root $delay={0.1 * idx}>
      {phase === 0 && (
        <>
          <CustomName>{player.name}</CustomName>
          <Container>
            <CustomLabel>Letters :</CustomLabel>
            <CustomValue>{player.letter || "---"}</CustomValue>
          </Container>
          <Container>
            <CustomLabel>Tries :</CustomLabel>
            {player.redo && <JokerIMG alt="joker" src={jokerSVG} />}
            <CustomValue data-testid={`trys ${idx}`}>
              {player.nbOfTry}
            </CustomValue>
          </Container>
          <ActionContainer>
            <CheckIMG
              data-testid={`trickdone ${idx}`}
              onClick={() => {
                setTimeout(() => {
                  handleTrickDone();
                }, 200);
                setCheckAnim(true);
              }}
              onAnimationEnd={() => setCheckAnim(false)}
              src={check}
              alt="check"
              $anim={checkAnim}
            />
            <CrossIMG
              data-testid={`trydown ${idx}`}
              onClick={() => {
                setTimeout(() => {
                  handleTryDown();
                }, 200);
                setCrossAnim(true);
              }}
              onAnimationEnd={() => setCrossAnim(false)}
              src={cross}
              alt="cross"
              $anim={crossAnim}
            />
          </ActionContainer>
        </>
      )}
      {phase === 1 && (
        <>
          <AnimatedCustomLabel>
            Use joker for {player.name} ?
          </AnimatedCustomLabel>
          <CustomBtn
            onClick={() => {
              setTimeout(() => {
                handleUseJoker();
              }, 200);
              setYesAnim(true);
            }}
            onAnimationEnd={() => setYesAnim(false)}
            $anim={yesAnim}
          >
            Yes
          </CustomBtn>
          <CustomBtn
            onClick={() => {
              setTimeout(() => {
                handleFailedTry();
              }, 200);
              setNoAnim(true);
            }}
            onAnimationEnd={() => setNoAnim(false)}
            $anim={noAnim}
          >
            No
          </CustomBtn>
        </>
      )}
      {phase === 2 && (
        <>
          <AnimatedCustomLabel style={{ fontSize: "150%" }}>
            Did {player.name} copy the trick ?
          </AnimatedCustomLabel>
          <CustomBtn
            onClick={() => {
              setTimeout(() => {
                handleTrickDone();
              }, 200);
              setYesAnim(true);
            }}
            onAnimationEnd={() => setYesAnim(false)}
            $anim={yesAnim}
          >
            Yes
          </CustomBtn>
          <CustomBtn
            onClick={() => {
              setTimeout(() => {
                handleFailedTry();
              }, 200);
              setNoAnim(true);
            }}
            onAnimationEnd={() => setNoAnim(false)}
            $anim={noAnim}
          >
            No
          </CustomBtn>
        </>
      )}
    </Root>
  );
}

export default PlayerItem;
