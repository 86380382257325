import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { CSSTransition } from "react-transition-group";

import TimeContext from "../context/time-context";
import GameContext from "../context/game-context";
import PlayerContext from "../context/player-context";
import chevron from "../img/flèche_retour_jaune.svg";
import plus from "../img/plus.svg";
import moins from "../img/moins.svg";

const placeholders = [
  "🛹 SKATE",
  "🏂 SNOW",
  "🕹️ OUT",
  "🌊 DIVE",
  "🛴 SCOOT",
  "🚲 BIKE",
  "🧗 CLIMB",
  "🌲 KENDAMA",
];
const Header = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  height: 5%;
  padding: 2%;
  animation: opacityAnim 1s linear 0.5s backwards;
`;
const Body = styled.div`
  position: absolute;
  top: 50px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90%;
  align-items: center;
`;
const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  animation: opacityAnim 1s ease-out;
`;
const Title = styled.h1`
  color: white;
  margin: 0;
  font-size: 200%;
  font-weight: bold;
  background: #090d0d;
  border-radius: 5px;
  padding: 0 3%;
`;
const SettingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
  gap: 15px;
  padding-top: 15px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  animation: opacityAnim 1s linear 0.25s backwards;
`;
const RowTries = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  height: ${({ $displayed }) => ($displayed ? "5rem" : "0rem")};
  opacity: ${({ $displayed }) => ($displayed ? "1" : "0")};
  transition: height 0.3s linear, opacity 0.1s linear;
  animation: opacityAnim 1s linear 0.25s backwards;
`;
const CustomLabel = styled.p`
  margin: 0;
  padding: 0 0 2.5% 0;
  color: #bff90b;
  font-size: 125%;
  user-select: none;
  font-weight: bold;
`;
const CustomPlaceholder = styled.p`
  padding: 0;
  margin: 0;
  position: absolute;
  left: 17%;
  user-select: none;
  color: #090d0d;
`;
const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;
const CustomInputWrapper = styled.div`
  display: flex;
  width: 100%;
  background: white;
  border-radius: 50px;
`;
const CustomInput = styled.input`
  padding: 0.5% 3%;
  height: 40px;
  width: 100% !important;
  border: none;
  border-radius: 50px;
  font-size: 125%;
`;
const CustomBtn = styled.button`
  padding: 2%;
  width: 60%;
  height: 10%;
  font-size: 175%;
  background: #bff90b;
  border: none;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #090d0d;
  font-family: "ClashGrotesk-Semibold";
  animation: opacityAnim 1s linear 0.5s backwards;
`;
const NbOfTryComponent = styled.p`
  margin: 0;
  text-align: center;
  padding: 2%;
  background: white;
  color: #090d0d;
  font-size: 175%;
  border: none;
  border-radius: 16px;
`;
const Img30px = styled.img`
  width: 30px;
`;
const CustomDesc = styled.p`
  color: white;
  background: #090d0d;
  font-size: 105%;
  border-radius: 5px;
  padding: 0 3%;
`;

function Setting() {
  const navigate = useNavigate();
  const trysRef = useRef(null);
  const startBtn = useRef(null);

  const { players, setPlayers } = useContext(PlayerContext);
  const { game, setGame } = useContext(GameContext);
  const { timer, setTimer } = useContext(TimeContext);

  const [placeholder, setPlaceholder] = useState(0);
  const [displayPlaceholder, setDisplayPlaceholder] = useState(
    game.targetWord.length ? false : true
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholder((placeholder + 1) % placeholders.length);
    }, 800);
    return () => clearInterval(interval);
  }, [placeholder]);

  useEffect(() => {
    startBtn?.current?.scrollIntoView(false);
  }, []);

  return (
    <div className="pagewrapper">
      <Header>
        <img onClick={() => navigate("/player")} src={chevron} alt="goback" />
      </Header>
      <Body>
        <TitleWrapper>
          <Title>Game</Title>
          <Title>Settings</Title>
        </TitleWrapper>
        <SettingWrapper>
          <Row>
            <CustomLabel>Game of</CustomLabel>
            <InputWrapper>
              {displayPlaceholder && (
                <CustomPlaceholder>{`${placeholders[placeholder]}`}</CustomPlaceholder>
              )}
              <CustomInput
                data-testid="targetword"
                id="targetword"
                type="text"
                value={game.targetWord}
                onChange={(e) =>
                  setGame({ ...game, targetWord: e.target.value.toUpperCase() })
                }
                onFocus={() => setDisplayPlaceholder(false)}
                onBlur={() => {
                  if (game.targetWord.length === 0) {
                    setDisplayPlaceholder(true);
                  }
                }}
              />
            </InputWrapper>
          </Row>
          <CSSTransition in={!game.isHard} timeout={320} nodeRef={trysRef}>
            <RowTries ref={trysRef} $displayed={!game.isHard}>
              <CustomLabel htmlFor="nbtry">Number of tries</CustomLabel>
              <CustomInputWrapper
                style={{ justifyContent: "space-evenly", alignItems: "center" }}
              >
                {game.nbOfTry > 1 && (
                  <Img30px
                    onClick={() => {
                      setPlayers(
                        players.map((p) => ({
                          ...p,
                          nbOfTry: parseInt(p.nbOfTry) - 1,
                        }))
                      );
                      setGame({ ...game, nbOfTry: parseInt(game.nbOfTry) - 1 });
                    }}
                    alt="moins"
                    src={moins}
                  />
                )}
                <NbOfTryComponent data-testid="gamenbtry" id="nbtry">
                  {game.nbOfTry}
                </NbOfTryComponent>
                <Img30px
                  onClick={() => {
                    setPlayers(
                      players.map((p) => ({
                        ...p,
                        nbOfTry: parseInt(p.nbOfTry) + 1,
                      }))
                    );
                    setGame({ ...game, nbOfTry: parseInt(game.nbOfTry) + 1 });
                  }}
                  alt="plus"
                  src={plus}
                />
              </CustomInputWrapper>
            </RowTries>
          </CSSTransition>
          <Row>
            <InputWrapper style={{ justifyContent: "space-between" }}>
              <CustomLabel htmlFor="hardmode">Hard Mode</CustomLabel>
              <input
                data-testid="hardmodecheckbox"
                id="hardmode"
                type="checkbox"
                defaultChecked={game.isHard}
                onChange={() => {
                  if (!game.isHard) {
                    setGame({ ...game, isHard: !game.isHard });
                    setPlayers(players.map((p) => ({ ...p, nbOfTry: 1 })));
                  } else {
                    setGame({ ...game, isHard: !game.isHard });
                  }
                }}
              />
            </InputWrapper>
            <CustomDesc>
              In the hard mode there is only one try. Even if you fail to
              define, you take a letter.
            </CustomDesc>
          </Row>
          <Row>
            <InputWrapper style={{ justifyContent: "space-between" }}>
              <CustomLabel htmlFor="joker">Joker</CustomLabel>
              <input
                data-testid="jokercheckbox"
                id="joker"
                type="checkbox"
                defaultChecked={game.allowRedo}
                onChange={() => {
                  setGame({ ...game, allowRedo: !game.allowRedo });
                  setPlayers(
                    players.map((p) => ({ ...p, redo: !game.allowRedo }))
                  );
                }}
              />
            </InputWrapper>
            <CustomDesc>
              Activate this option for a unique bonus try to use once in a game
              when you have to copy a trick
            </CustomDesc>
          </Row>
          <CustomBtn
            ref={startBtn}
            onClick={() => {
              setGame({
                ...game,
                isRunning: true,
                targetWord: game.targetWord.length
                  ? game.targetWord
                  : game.defaultTargetWord,
              });
              setPlayers(players.map((p) => ({ ...p, redo: game.allowRedo })));
              setTimer({ ...timer, isActive: true, isPaused: false });
              navigate("/game");
            }}
          >
            Start Game
          </CustomBtn>
        </SettingWrapper>
      </Body>
    </div>
  );
}

export default Setting;
