import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";

import formatTime from "../utils/format-time";
import TimeContext from "../context/time-context";
import GameContext from "../context/game-context";
import PlayerContext from "../context/player-context";
import house from "../img/home.svg";
import restart from "../img/restart.svg";

const slide = keyframes`
  from {
    opacity: 0;
    transform: translateY(250px)
  } to {
    opacity: 1;
    transform: translateY(0)
  }
`;
const Root = styled.div`
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  background: #bff90b;
`;
const CustomTime = styled.h1`
  text-align: center;
  font-size: 175%;
  font-weight: bold;
  color: #090d0d;
  margin: 0;
  animation: opacityAnim 0.5s ease-out 0.5s backwards;
`;
const StatContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  max-height: 500px;
  align-items: center;
  gap: 10px;
  overflow: auto;
  color: white;
`;
const PlayerContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 80%;
  min-height: 420px;
  gap: 5px;
  align-items: center;
  background: #090d0d;
  border-radius: 25px;
  padding: 2% 4%;
  animation: ${slide} 0.5s linear ${({ $delay }) => $delay}s backwards;
`;
const PlayerName = styled.h3`
  margin: 1%;
  font-weight: bold;
  font-size: 150%;
  overflow: scroll;
  text-decoration: 1px underline;
  max-width: 275px;
  min-height: 30px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 90%;
  height: 20%;
  margin: 0;
  font-size: 110%;
`;
const BtnsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  padding: 0 5%;
  justify-content: space-evenly;
  animation: opacityAnim 1s ease-out 1s backwards;
`;
const Label = styled.p`
  margin: 0;
  color: #bff90b;
`;
const Value = styled.p`
  margin: 0;
  background: #fff;
  width: 10%;
  border-radius: 25px;
  color: #090d0d;
  text-align: center;
`;
const HomeImg = styled.img`
  width: 75px;
`;
const RestartImg = styled.img`
  width: 60px;
`;
const Title = styled.h1`
  margin: 0;
  text-align: center;
  font-size: 200%;
  animation: opacityAnim 1s ease-out backwards;
`;

function EndGame({ restartFunc }) {
  const navigate = useNavigate();

  const { players } = useContext(PlayerContext);
  const { timer } = useContext(TimeContext);
  const { game } = useContext(GameContext);

  return (
    <Root>
      <BtnsContainer>
        <RestartImg onClick={() => restartFunc()} src={restart} alt="restart" />
        <HomeImg onClick={() => navigate("/")} src={house} alt="house" />
      </BtnsContainer>
      <div>
        <Title>END GAME</Title>
        <CustomTime>{formatTime(timer.timeCounter)}</CustomTime>
        <CustomTime>spent in a game of {game.targetWord}</CustomTime>
      </div>
      <StatContainer>
        {players.map((p, i) => (
          <PlayerContainer key={i} $delay={0.1 * i + 1}>
            <PlayerName>
              {p.name} with {p.letter || "no letters !"}
            </PlayerName>
            <Row>
              <Label>Tricks Defined</Label>
              <Value>{p.stats.nbDef}</Value>
            </Row>
            <Row>
              <Label>Copied tricks</Label>
              <Value>{p.stats.nbCopied}</Value>
            </Row>
            <Row>
              <Label>Failed to define</Label>
              <Value>{p.stats.nbFailedDef}</Value>
            </Row>
            <Row>
              <Label>Failed to copy</Label>
              <Value>{p.stats.nbFailedTry}</Value>
            </Row>
            <Row>
              <Label>Letters given to others</Label>
              <Value>{p.stats.nbLetterGiven}</Value>
            </Row>
          </PlayerContainer>
        ))}
      </StatContainer>
    </Root>
  );
}

export default EndGame;
