import React, { useState, useRef, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes, css } from "styled-components";
import { CSSTransition } from "react-transition-group";

import PlayerContext, { initialPlayers } from "../context/player-context";
import GameContext, { initialGame } from "../context/game-context";
import TimeContext, { initialTime } from "../context/time-context";
import insta from "../img/insta.svg";
import mail from "../img/mail.svg";
import github from "../img/github.svg";
import SVGPlay from "../img/double_flèche_page_accueil.svg";
import biglogo from "../img/logo_entier.svg";

const slidefromtop = keyframes`
  from {
    transform: translateY(900px)
  } to {
    transform: translateY(0)
  }
`;
const treflip = keyframes`
0% {
  transform: rotateX(0deg);
  transform: rotateY(0deg);
}
12% {
  transform: rotateY(-45deg);
}
25% {
  transform: rotateX(-90deg);
}
50% {
  transform: rotateY(-180deg);
}
75% {
  transform: rotateX(-270deg);
}
100% {
  transform: rotateX(-360deg);
  transform: rotateY(-360deg);
}
`;
const Root = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 5%;
`;
const LogoWrapper = styled.div`
  display: flex;
  width: 85%;
  height: 65%;
  align-items: center;
  justify-content: center;
  background: #bff90b;
  border-radius: 25px;
  animation: ${slidefromtop} 0.7s cubic-bezier(0, -0.13, 0.61, 1.37);
`;
const Logo = styled.img`
  width: 300px;
  animation: opacityAnim 1.2s linear 0.6s backwards
    ${({ $tre }) =>
      $tre
        ? css`
            , ${treflip} 0.6s linear
          `
        : ""};
`;
const PlayBtnWrapper = styled.div`
  display: flex;
  width: 45%;
  align-items: center;
  justify-content: space-between;
  padding: 0 4%;
  border: 3px solid white;
  border-radius: 40px;
  animation: opacityAnim 1.2s linear 0.4s backwards;
`;
const CustomPlayText = styled.p`
  color: white;
  font-size: 275%;
  margin: 0;
  font-weight: bold;
  padding-left: 15px;
  padding-bottom: 5px;
  animation: opacityAnim 1s linear 0.7s backwards;
`;
const PlayImg = styled.img`
  width: 70px;
  animation: opacityAnim 1.2s linear 1s backwards;
`;
const SocialsWrapper = styled.div`
  margin-left: ${({ $isOpen }) => ($isOpen ? "0" : "40px")};
  display: flex;
  width: 100%;
  justify-content: space-around;
  animation: ${slidefromtop} 1s ease-out 0.2s backwards;
`;
const CustomSVG = styled.img`
  width: 60px;
`;
const Background = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ $isOpen }) => ($isOpen ? "50%" : "0")};
  height: 120%;
  align-items: center;
  justify-content: space-evenly;
  background: #bff90b;
  transition: all 0.15s ease-in;
  border-radius: 3px;
`;
const CustomLink = styled.a`
  user-select: none;
  color: #090d0d;
  text-decoration: none;
  white-space: nowrap;
`;

function Home() {
  const navigate = useNavigate();
  const popupRef = useRef(null);

  const { setPlayers } = useContext(PlayerContext);
  const { setGame } = useContext(GameContext);
  const { setTimer } = useContext(TimeContext);

  const [isOpen, setIsOpen] = useState(false);
  const [tre, setTre] = useState(false);

  useEffect(() => {
    setPlayers(initialPlayers.players);
    setGame(initialGame.game);
    setTimer(initialTime.timer);
  }, []);

  return (
    <div className="pagewrapper">
      <Root>
        <div className="messagedefilant">
          <div data-text="GAME OF OUT • GAME OF OUT • GAME OF OUT • ">
            <span>GAME OF OUT • GAME OF OUT • GAME OF OUT •</span>
          </div>
        </div>
        <div className="messagedefilant2">
          <div data-text="GAME OF OUT • GAME OF OUT • GAME OF OUT • ">
            <span>GAME OF OUT • GAME OF OUT • GAME OF OUT •</span>
          </div>
        </div>
        <LogoWrapper>
          <Logo
            $tre={tre}
            onClick={() => setTre(true)}
            onAnimationEnd={() => setTre(false)}
            src={biglogo}
            alt="logo"
          />
        </LogoWrapper>
        <PlayBtnWrapper onClick={() => navigate("/player")}>
          <CustomPlayText>Play</CustomPlayText>
          <PlayImg alt="play" src={SVGPlay} />
        </PlayBtnWrapper>
        <SocialsWrapper $isOpen={isOpen}>
          <a target="blank" href="https://gitlab.com/skilfull/gameofout">
            <CustomSVG src={github} alt="github" />
          </a>
          <a href="mailto:cyrilb38@hotmail.com">
            <CustomSVG src={mail} alt="mail" />
          </a>
          <CustomSVG
            src={insta}
            alt="insta"
            onClick={() => setIsOpen(!isOpen)}
          />
          <CSSTransition in={isOpen} timeout={300} nodeRef={popupRef}>
            <Background ref={popupRef} $isOpen={isOpen}>
              {isOpen && (
                <>
                  <CustomLink
                    href="https://www.instagram.com/cyrheel_/"
                    target="blank"
                  >
                    @Cyrheel_📠
                  </CustomLink>
                  <CustomLink
                    href="https://www.instagram.com/euphoria__graphics/"
                    target="blank"
                  >
                    @Euphoria_graphics🎨
                  </CustomLink>
                </>
              )}
            </Background>
          </CSSTransition>
        </SocialsWrapper>
      </Root>
    </div>
  );
}

export default Home;
