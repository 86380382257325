import React from "react";
import { useRouteError, useNavigate } from "react-router-dom";

function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  return (
    <div>
      <h1>Oops :/</h1>
      <p>
        Error msg: <i>{error.statusText || error.message}</i>
      </p>
      <p>
        Please contact me here to report any bug
        <br />
        <a target="blank" href="mailto:cyrilb38@hotmail.com">
          cyrilb38@hotmail.com
        </a>
      </p>
      <button onClick={() => navigate("/")}>Back to home</button>
    </div>
  );
}

export default ErrorPage;
