import React, {
  createRef,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled, { keyframes, css } from "styled-components";
import { CSSTransition, TransitionGroup } from "react-transition-group";

import PlayerContext from "../context/player-context";
import GameContext from "../context/game-context";
import plus from "../img/addplayer.svg";
import xCircle from "../img/croix.svg";

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(200px)
  } to {
    opacity: 1;
    transform: translateY(0)
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  height: 65%;
  gap: 20px;
`;
const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 400px;
  align-items: center;
  overflow: auto;
  scroll-behavior: smooth;
  gap: 10px;
`;
const Item = styled.div`
  display: flex;
  width: 90%;
  background: #f5f5f5;
  border-radius: 50px;
  min-height: 70px;
  ${({ $plength, $delay }) =>
    $plength
      ? ""
      : css`
          animation: ${slideIn} 1s ${$delay}s backwards;
        `};
`;
const Name = styled.input`
  padding: 0 0 0 10%;
  width: 80%;
  font-size: 130%;
  border: none;
  border-radius: 50px;
  background: #f5f5f5;
  font-family: "ClashGrotesk-Semibold";
  &:focus {
    outline: none;
  }
`;
const AddPlayerBtn = styled.img`
  padding: 2%;
  width: 50px;
  border-radius: 50%;
  animation: opacityAnim 1s linear 0.7s backwards;
`;
const DeleteBtn = styled.div`
  display: flex;
  justify-content: center;
  width: 30px;
  padding-right: 5%;
  opacity: ${({ $show }) => ($show ? "1" : "0")};
  transition: all 0.15s linear;
`;
const DeleteImg = styled.img`
  width: 30px;
`;

function PlayerList() {
  const addBtnRef = useRef(null);
  const deleteRef = useRef(null);

  const { players, setPlayers } = useContext(PlayerContext);
  const { game } = useContext(GameContext);

  const [showDelete, setShowDelete] = useState(false);

  function addPlayer() {
    const nextPlayer = {
      name: `Player ${players.length + 1}`,
      letter: "",
      position: players.length,
      redo: game.allowRedo,
      nbOfTry: game.nbOfTry,
      stats: {
        nbDef: 0,
        nbFailedDef: 0,
        nbCopied: 0,
        nbFailedTry: 0,
        nbLetterGiven: 0,
      },
    };
    setPlayers([...players, nextPlayer]);
  }
  function setPlayerName(e, i) {
    setPlayers(
      players.map((player, idx) => {
        if (idx === i) {
          return { ...player, name: e.target.value };
        } else {
          return player;
        }
      })
    );
  }
  function resetPlayerName(i) {
    setPlayers(
      players.map((player, idx) => {
        if (idx === i) {
          return { ...player, name: "" };
        } else {
          return player;
        }
      })
    );
  }
  function removePlayer(i) {
    const nextPlayers = players
      .filter((p, id) => i !== id)
      .map((p, id) => ({
        ...p,
        position: id,
        name: p.name.startsWith("Player") ? `Player ${id + 1}` : p.name,
      }));
    setPlayers(nextPlayers);
  }

  useEffect(() => {
    addBtnRef?.current?.scrollIntoView();
    setShowDelete(players.length > 2);
  }, [players.length]);

  return (
    <Container>
      <List>
        <TransitionGroup component={null}>
          {players.map((p, i) => {
            const itemRef = createRef(null);
            return (
              <CSSTransition
                key={i}
                timeout={125}
                nodeRef={itemRef}
                classNames="item"
              >
                <Item ref={itemRef} $plength={i >= 2} $delay={0.25 * i}>
                  <Name
                    data-testid={`Player ${i}`}
                    type="text"
                    value={p.name}
                    onChange={(e) => setPlayerName(e, i)}
                    onFocus={() => resetPlayerName(i)}
                  />
                  <CSSTransition
                    in={showDelete}
                    timeout={100}
                    nodeRef={deleteRef}
                  >
                    <>
                      {showDelete && (
                        <DeleteBtn
                          ref={deleteRef}
                          data-testid={`remove ${i}`}
                          onClick={() => removePlayer(i)}
                          $show={showDelete}
                        >
                          <DeleteImg src={xCircle} alt="remove" />
                        </DeleteBtn>
                      )}
                    </>
                  </CSSTransition>
                </Item>
              </CSSTransition>
            );
          })}
        </TransitionGroup>
        <AddPlayerBtn
          ref={addBtnRef}
          onClick={() => addPlayer()}
          alt="add-player"
          src={plus}
        />
      </List>
    </Container>
  );
}

export default PlayerList;
