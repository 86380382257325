import React from "react";
import styled from "styled-components";

const CustomH1 = styled.h1`
  margin: 0 7%;
  padding: 3% 5%;
  font-size: 200%;
  color: ${({ $color }) => ($color ? "#090D0D" : "#fff")};
  background: ${({ $color }) => ($color ? "#bff90b" : "#090D0D")};
  text-align: center;
  border-radius: 40px;
  animation: opacityAnim 1s ease-out backwards;
`;

function DynamicSentence({ name, action }) {
  return (
    <CustomH1 $color={action === "copy"}>
      {action === "define"
        ? `It's ${name}'s turn ! The trick was defined ?`
        : `Who can copy this trick ?`}
    </CustomH1>
  );
}

export default DynamicSentence;
